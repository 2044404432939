<template>
  <div class="back-index">
    <div class="index-box">
      <div class="index-title">欢迎您登录鸿才志愿管理系统</div>
      <div class="user-info">
        <div class="img">
          <img src="../../assets/img/default-user.jpg" alt="" />
        </div>
        <div class="name">{{ $store.getters.getAdminInfo.fullname }}</div>
      </div>
      <div class="date-info">{{ date }}</div>
    </div>
  </div>
</template>
<script>
export default {
  name: "backIndex",
  components: {
    
  },
  props: {
    msg: String,
  },
  data() {
    return {
      date:''
    };
  },
  mounted(){
    this.getDate();
    setInterval(()=>{
      this.getDate();
    },1000)
  },
  methods: {
    getDate(){
      let weeks = ['星期日','星期一','星期二','星期三','星期四','星期五','星期六']
      let weekIndex = new Date().getDay();
      let week = weeks[weekIndex];
      let yy = new Date().getFullYear();
      let mm = new Date().getMonth()+1;
      let dd = new Date().getDate();
      let hh = new Date().getHours();
      let mf = new Date().getMinutes() <10 ? "0"+ new Date().getMinutes():new Date().getMinutes();
      let ss = new Date().getSeconds <10? "0"+ new Date().getSeconds():new Date().getSeconds();
      this.date = yy + "/" + mm + "/" + dd + " " +  hh + ":" + mf + ":" + ss + " " + week;
    }
  },
};
</script>
<style lang="less" scoped>
.back-index {
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  background: url("../../assets/img/back-index-bg.jpg") no-repeat;
  .index-box {
    height: auto;
    width: 700px;
    padding-top: 15%;
    padding-left: 10%;
    padding-bottom: 20%;
  }
  .index-title {
    color: rgba(0, 0, 0, 1);
    font-family:SourceHanSansSC;
    font-weight:700;
    font-size: 40px;
    padding-bottom: 10px;
    margin-bottom: 30px;
    border-bottom: 3px solid rgba(100, 100, 100, 100);
  }

  .user-info {
    display: flex;
    align-items: center;
    margin: 0 0 20px 100px;
    .img {
      width: 88px;
      height: 88px;
      box-shadow: 0px 2px 6px 0px rgba(255, 69, 11, 48);
      border: 2px solid rgba(255, 255, 255, 100);
      border-radius: 50%;
      margin-right: 10px;
      overflow: hidden;
      img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
      }
    }
    .name {
      font-family:SourceHanSansSC; 
      font-weight:700;
      color: rgba(16, 16, 16, 100);
      font-size: 23px;
      font-weight: 700;
    }
  }

  .date-info {
    margin-left: 100px;
    font-family:SourceHanSansSC; 
    font-weight:400;
    color: rgba(16, 16, 16, 100);
    font-size: 18px;
  }
}
</style>